import PersonalInformation from "../components/PersonalInformation";
import {useForm} from "antd/lib/form/Form";
import {Button, Form, message, notification, Steps} from "antd";
import Address from "../components/Address";
import InformationReceived from "../components/InformationReceived";
import BachelorFamilyInformation from "../components/bachelor/BachelorFamilyInformation";
import FormButtons from "../components/FormButtons";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {RiSaveFill} from "react-icons/ri";
import UserDisabilityForm from "../components/UserDisabilityForm";
import LanguageLevel from "../components/LanguageLevel";
import {admitService} from "../service/admit.service";
import {StepsUI} from "../entity/admit.model";
import GraduationInformation from "../components/GraduationInformation";
import BachelorSatInformation from "../components/bachelor/BachelorSatInformation";
import UploadInformation from "../components/UploadInformation";
import UserEmploymentForm from "../components/UserEmploymentForm";
import UserProfessionSelectForm from "../components/UserProfessionSelectForm";
import CustomLoader from "../../../public/components/CustomLoader";
import Regulation from "../components/Regulation";
import {UserConfirmStyle} from "./UserConfirmStyle";
import PaymentComponent from "../components/PaymentComponent";
import SuccessComponent from "../components/SuccessComponent";
import BachelorBranchSatInformation from "../components/bachelor/BachelorBranchSatInformation";

const {Step} = Steps;

export default function UserConfirm() {
    const [form] = useForm();
    const navigate = useNavigate();
    const location: any = useLocation();
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [steps, setSteps] = useState<StepsUI[]>([]);
    const [check, setCheck] = useState<boolean>(false);
    const [checkBox, setCheckBox] = useState<boolean>(true);
    const [selectedConfigAction, setSelectedConfigAction] = useState("");
    const [admissionUser, setAdmissionUser] = useState<any>()
    const [paymentState, setPaymentState] = useState<boolean>(false);

    const {admitId} = useParams();

    const next = () => {
        setStep(step + 1);
    };

    const prev = () => {
        setStep(step - 1);
    };

    const personalInformation = {
        title: "Бүртгүүлэгчийн товч мэдээлэл",
        content: <PersonalInformation/>,
        form: "personalInformation",
    };

    const userAddress = {
        title: "Хаяг, холбоо барих мэдээлэл",
        content: <Address/>,
        form: "userAddress",
    };

    const userEducation = {
        title: "Төгссөн сургуулийн мэдээлэл",
        content: <GraduationInformation/>,
        form: "userEducation",
    };

    const userFile = {
        title: "Бусад мэдээлэл /хавсралт/",
        content: <UploadInformation/>,
        form: "userFile",
    };

    const userFamilyInfo = {
        title: "Гэр бүлийн мэдээлэл",
        content: <BachelorFamilyInformation/>,
        form: "familyInformation",
    };

    const userLanguage = {
        title: "Хэлний мэдлэгийн түвшин",
        content: <LanguageLevel/>,
        form: "userLanguage",
    };

    const userEmployment = {
        title: "Ажил эрхлэлт",
        content: <UserEmploymentForm/>,
        form: "userEmployment",
    };

    const userProfession = {
        title: "Мэргэжил сонгох",
        content: <UserProfessionSelectForm/>,
        form: "userProfession",
    };

    const userSat = {
        title: "ЭЕШ-ын мэдээлэл",
        content: <BachelorSatInformation setCheckBox={setCheckBox}/>,
        form: "userSat",
    };

    const userSatBranch = {
        title: "ЭЕШ-ын мэдээлэл",
        content: <BachelorBranchSatInformation setCheckBox={setCheckBox}/>,
        form: "userSatBranch",
    };

    const userDisabilityForm = {
        title: "Хөгжлийн бэрхшээлтэй эсэх",
        content: <UserDisabilityForm/>,
        form: "userDisabilityForm",
    };

    const informationReceived = {
        title: "СЭЗИС-ийн мэдээлэл авсан суваг",
        content: <InformationReceived check={check}/>,
        form: "informationReceived",
    };

    function onFinish() {
        navigate("/user/home");
    }

    function onFinishConfirm() {
        navigate("/user/home");
    }


    useEffect(() => {
        admitService.createAdmissionUser(admitId).then((res) => {
            let state = res.admissionUser.state;
            let paymentId = 0;
            setAdmissionUser(res.admissionUser)
          let admissionUserInfo = res.admissionUser;
            admissionUserInfo['user'] = res.user
          admitService.getAdmissionConfig(admitId).then(async (response) => {
                setName(response.admissions.name);
                if (state === "R") {
                    await admitService.getPaymentCredit(admitId).then((response) => {
                        if (response.payment.state === "NOT_PAID") {
                            paymentId = response.payment.id
                            setPaymentState(true);
                        }
                    });
                } else if (state === "C") {
                    setCheck(true);
                    setPaymentState(false);
                } else {
                    await admitService.getPayment(admitId).then((response) => {
                        if (response.payment.state === "NOT_PAID") {
                            paymentId = response.payment.id
                            setPaymentState(true);
                        }
                    });
                }
                let data: any;
                let configCheck = false;
                setSelectedConfigAction(response.admissions.state);
                let configs: any;
                await response.admissions.configs.forEach((element: any) => {
                    console.log(state, element.action, location.state?.clickButtonType, response.admissions.state)
                    if (state === "P" && response.admissions.state === "register" && location.state?.clickButtonType !== "confirm" && element.action === "register" && element.isDeleted === false) {
                        if (location.state?.clickButtonType === "confirm") {
                            notification.error({
                                message: 'Бүртгэлгүй хэрэглэгч.'
                            })
                            navigate('/user/home')
                        }
                        configs = element;
                    } else if ((state === "R" || state === "C") && (response.admissions.state === "finished" || location.state?.clickButtonType === "confirm-registered") && (element.action === "confirm" && element.isDeleted === false)) {
                        configs = element
                        console.log(element)
                    }
                    if (element.action === 'confirm' && element.isDeleted === false) {
                        configCheck = true
                    }
                });
                console.log(configs, "configs")
                if (configs === undefined) {
                    notification.error({
                        message: 'Хөтөлбөрийн хугацаа дууссан.'
                    })
                    navigate('/user/home')
                } else {
                    data = configs.configData.user_info
                }
                if (!configCheck && location.state?.clickButtonType === "confirm") {
                    notification.error({
                        message: 'Дэлгэрэнгүй анкет нээгдээгүй байна.'
                    })
                    navigate('/user/home')
                }
                const regulation = {
                    title: "Журам",
                    content: <Regulation setCheckBox={setCheckBox} satExam={response.admissions.satExam}/>,
                    form: "regulation",
                };

                if (data.user_info_basic === true) {
                    navigate("/user/admit/" + admitId + "/bachelor/pre");
                } else {
                    setSteps([]);
                    setSteps((array) => [...array, regulation]);
                    if (data.user_info === true) {
                        setSteps((array) => [...array, personalInformation]);
                    }
                    if (data.user_disability === true) {
                        setSteps((array) => [...array, userDisabilityForm]);
                    }
                    if (data.user_addressing === true) {
                        setSteps((array) => [...array, userAddress]);
                    }
                    if (data.user_education === true) {
                        setSteps((array) => [...array, userEducation]);
                    }
                    if (data.user_file === true) {
                        setSteps((array) => [...array, userFile]);
                    }
                    if (data.user_family_info === true) {
                        setSteps((array) => [...array, userFamilyInfo]);
                    }
                    if (data.user_language === true) {
                        setSteps((array) => [...array, userLanguage]);
                    }
                    if (data.user_employment === true) {
                        setSteps((array) => [...array, userEmployment]);
                    }
                    if (response.admissions.satExamBranch === true && location.state?.clickButtonType !== "confirm" && location.state?.clickButtonType !== "confirm-registered") {
                        setSteps((array) => [...array, userSatBranch]);
                    }
                    if (response.admissions.satExam === true && location.state?.clickButtonType !== "confirm" && location.state?.clickButtonType !== "confirm-registered") {
                        setSteps((array) => [...array, userSat]);
                    } else {
                        if (data.user_profession === true && location.state?.clickButtonType !== "confirm" && response.admissions.satExam === false) {
                            setSteps((array) => [...array, userProfession]);
                        }
                    }
                    if (location.state?.clickButtonType !== "confirm" && location.state?.clickButtonType !== "confirm-registered") {
                        const payment = {
                            title: location.state?.clickButtonType === "confirm" ? "Кредитийн хураамж" : "Төлбөр-бүртгэлийн хураамж",
                            content: <PaymentComponent admitId={admitId} paymentId={paymentId}
                                                       setCheckBox={setCheckBox}/>,
                            form: "payment",
                        };

                        setSteps((array) => [...array, payment]);
                        setSteps((array) => [...array, informationReceived]);
                    }
                    else if (location.state?.clickButtonType === "confirm-registered" && (response.admissions.code.startsWith('BBAe') || response.admissions.code.startsWith('МBA'))){
                        const payment = {
                            title: "Кредитийн хураамж",
                            content: <PaymentComponent admitId={admitId} paymentId={paymentId}
                                                       setCheckBox={setCheckBox}/>,
                            form: "payment",
                        };

                        setSteps((array) => [...array, payment]);
                    }
                    let success = {
                        title: "Амжилттай бүртгүүллээ",
                        content: <SuccessComponent setCheckbox={setCheckBox}
                                                   text={"СЭЗИС-д амжилттай бүртгүүллээ танд баяр хүргэе!"}/>,
                        form: "success",
                    };

                    if (location.state?.clickButtonType === "confirm" || location.state?.clickButtonType === "confirm-registered") {
                        success = {
                            title: "Амжилттай элслээ",
                            content: <SuccessComponent setCheckbox={setCheckBox} clickButtonType={location.state?.clickButtonType}
                                                       satExam={response.admissions.satExam}
                                                       admissionUser={admissionUserInfo}
                                                       text={"СЭЗИС-д амжилттай элссэн танд баяр хүргэе!"}/>,
                            form: "success",
                        };
                    }
                    setSteps((array) => [...array, success]);
                }
                setLoading(false);
            });
        });
    }, []);

    const onChangeStep = (value: number) => {
        if (admissionUser.registerStep >= value) {
            setStep(value)
            setCheckBox(false)
        }
    }

    const onFormChange = (name: number) => {
        if (admissionUser.registerStep < step) {
            admitService.changeAdmissionUserStep(admissionUser.id, step.toString(), admissionUser.state === "R" ? 'confirm' : 'register').then((res) => {
                setAdmissionUser(res.admissionUser)
            })
        }
    }

    return (
        <Form.Provider
            onFormFinish={(name) => {
                if (name === "informationReceived" && (location.state?.clickButtonType !== "confirm" || location.state?.clickButtonType !== "confirm-registered")) {
                    onFinish();
                } else if (name === "success" && (location.state?.clickButtonType === "confirm" || location.state?.clickButtonType === "confirm-registered")) {
                    onFinishConfirm();
                } else {
                    next();
                }
            }}
            onFormChange={(name: any) => {
                onFormChange(name)
            }}
        >
            {loading ? (
                <CustomLoader/>
            ) : (
                <UserConfirmStyle>
                    <h1 className="font-weight-bold">Элсэлтийн бүртгэл</h1>
                    <div className="mt-3 row">
                        <div className="col-md-3 mb-3 steps-background">
                            <p className="step-title">Бүртгэл баталгаажих алхамууд</p>
                            <Steps
                                direction="vertical"
                                current={step}
                                onChange={onChangeStep}
                            >
                                {steps.map((item) => (
                                    <Step key={item.title} title={item.title}/>
                                ))}
                            </Steps>
                        </div>
                        <div className="col-md-1"/>
                        <div className="col-md-8">
                            <>{steps[step].content}</>
                        </div>
                    </div>
                    <div className="steps-action">
                        <div className="row">
                            <div className="col-md-4"/>
                            <div className="col-md-4 d-flex">
                                {step < steps.length - 1 && (
                                    <Button
                                        type="primary"
                                        className="submit-btn"
                                        htmlType="submit"
                                        disabled={checkBox}
                                        form={steps[step].form}
                                    >
                                        Дараах
                                    </Button>
                                )}
                                {step === steps.length - 1 && (
                                    <>
                                        {paymentState ? (
                                            <>
                                                {selectedConfigAction === "register" ? (
                                                    <Button
                                                        type="primary"
                                                        className="submit-btn"
                                                        htmlType="submit"
                                                        form={steps[step].form}
                                                        disabled={checkBox}
                                                    >
                                                        <RiSaveFill size={24} className="mr-2"/>
                                                        Дуусгах
                                                    </Button>
                                                ) : !check ? (
                                                    <Button
                                                        type="primary"
                                                        className="submit-btn"
                                                        htmlType="submit"
                                                        form={steps[step].form}
                                                        disabled={checkBox}
                                                    >
                                                        <RiSaveFill size={24} className="mr-2"/>
                                                        Дуусгах
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="primary"
                                                        className="submit-btn"
                                                        htmlType="submit"
                                                        form={steps[step].form}
                                                    >
                                                        <RiSaveFill size={24} className="mr-2"/>
                                                        Хадгалах
                                                    </Button>
                                                )}
                                                {/* <Button
                         type="primary"
                         className="submit-btn"
                         htmlType="submit"
                         form={steps[step].form}
                       >
                         <RiSaveFill size={24} className="mr-2" />
                         Хураамж төлөх
                       </Button> */}
                                            </>
                                        ) : (
                                            <>
                                                {selectedConfigAction === "register" ? (
                                                    <Button
                                                        type="primary"
                                                        className="submit-btn"
                                                        htmlType="submit"
                                                        form={steps[step].form}
                                                        disabled={checkBox}
                                                    >
                                                        <RiSaveFill size={24} className="mr-2"/>
                                                        Дуусгах
                                                    </Button>
                                                ) : !check ? (
                                                    <Button
                                                        type="primary"
                                                        className="submit-btn"
                                                        htmlType="submit"
                                                        form={steps[step].form}
                                                        disabled={checkBox}
                                                    >
                                                        <RiSaveFill size={24} className="mr-2"/>
                                                        Дуусгах
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="primary"
                                                        className="submit-btn"
                                                        htmlType="submit"
                                                        form={steps[step].form}
                                                    >
                                                        <RiSaveFill size={24} className="mr-2"/>
                                                        Хадгалах
                                                    </Button>
                                                )}
                                                {/* <Button
                         type="primary"
                         className="submit-btn"
                         htmlType="submit"
                         form={steps[step].form}
                       >
                         <RiSaveFill size={24} className="mr-2" />
                         Хадгалах
                       </Button> */}
                                            </>
                                        )}
                                    </>
                                )}
                                {step > 0 && (
                                    <Button
                                        className="back-btn"
                                        style={{margin: "0 8px"}}
                                        onClick={() => prev()}
                                    >
                                        Буцах
                                    </Button>
                                )}
                            </div>
                            <div className="col-md-4"/>
                        </div>
                    </div>
                    <FormButtons/>
                </UserConfirmStyle>
            )}
        </Form.Provider>
    );
}


